@use "../../../scss/styles/colors" as *;

.top_drop {
  display: flex;
  gap: 2px;

  @media(max-width: 1024px) {
    height: 48px;
  }
  &>:first-child {
    min-width: 188px;
    width: 68px;
    height: 68px;      
    padding-left: 20px;
    padding-right: 20px;

    @media(max-width: 1024px) {
      min-width: 96px;

      .top_drop_item_view_image {
        right: 16px;
      }
    }
  }
  &_item {
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    max-height: 82px;
    min-width: 132px;
    margin-right: 2px;
    background: $top_drop_item_consumer;
    overflow: hidden;

    @media(max-width: 1024px) {
      width: 80px;
      height: 48px;
      min-width: 80px;
    }
    &:before {
      content: "";
      position: absolute;
      top: -4px;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 16px);
      height: 8px;
      background: $top_drop_item_consumer;
      border-radius: 9999px;
      z-index: 1;
    }
    &:hover {
      .top_drop_item_view_hover {
        opacity: 1;
      }
      .top_drop_item_view {
        opacity: 0;
      }
      .top_drop_item_vip_icon {
        opacity: 0;
      }
    }
  }
  &_item_vip {
    &_icon {
      position: absolute;
      top: 4px;
      right: 4px;
      width: 74px;
      height: 74px;
      transition: opacity 0.3s ease-in-out;

      @media(max-width: 1024px) {
        transform: scale(.6);
        top: -10px;
        right: -10px;
      }
    }
    .top_drop_item_view_image {
      right: 24px;

      @media(max-width: 1024px) {
        width: 64px;
        height: 48px;
        right: 8px;
      }
    }
  }
  &_item_view {
    transition: opacity 0.3s ease-in-out;

    img {
      @media(max-width: 1024px) {
        width: 64px;
        height: 48px;
      }
    }
  }
  &_item_consumer {
    background: $top_drop_item_consumer;
    color: $rarity_consumer;

    &:before {
      background: $top_drop_item_consumer;
    }
  }
  &_item_military {
    background: $top_drop_item_military;
    color: $rarity_military;
    &:before {
      background: $top_drop_item_military;
    }
  }
  &_item_industrial {
    background: $top_drop_item_industrial;
    color: $rarity_industrial;

    &:before {
      background: $top_drop_item_industrial;
    }
  }
  &_item_restricted {
    background: $top_drop_item_restricted;  
    color: $rarity_restricted;

    &:before {
      background: $top_drop_item_restricted;
    }
  }
  &_item_classified {
    background: $top_drop_item_classified; 
    color: $rarity_classified;

    &:before {
      background: $top_drop_item_classified;
    }
  }
  &_item_covert {
    background: $top_drop_item_covert;
    color: $rarity_covert;
    &:before {
      background: $top_drop_item_covert;
    }
  }
  &_item_legendary {
    background: $top_drop_item_legendary;
    color: $rarity_legendary;

    &:before {
      background: $top_drop_item_legendary;
    }
  }
  &_item_view_content {
    display: flex;
    flex-direction: column;
    gap: 2px;
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    align-items: flex-start;
    padding: 0 8px 8px 8px;

    @media(max-width: 1024px) {
      padding: 0 4px 4px 4px;
    }
  }
  &_item_view_percent {
    font-size: 10px;
    font-weight: 500;
    padding: 3px;
    border-radius: 4px;
    background: rgba(19, 19, 24, .5);
  }
  &_item_view_name {
    font-weight: 500;
    font-size: 12px;
    line-height: 1.16667;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
    //white-space: normal;

    @media(max-width: 1024px) {
      display: none;
    }
  }
  &_item_view_image {
    position: absolute;
    top: 0;
    right: 4px;
  }
  &_item_view_hover {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - 16px);
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-around;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  &_item_view_hover_ava {
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: center;
    font-weight: 500;
    line-height: 1.16667;
    color: #B3B5C6;
    font-size: 12px;

    img {
      border-radius: 8px;

      @media(max-width: 1024px) {
        width: 24px;
        height: 24px;
      }
    }
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 56px;

      @media(max-width: 1024px) {
        display: none;
      }
    }
  }
  &_item_view_hover_case {
    width: 60px;
    height: 60px;

    @media(max-width: 1024px) {
      width: 32px;
      height: 32px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
