.menu_mob {
  align-items: flex-end;
  margin: 0 auto;
  gap: 4px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: none;
  padding: 4px 16px 12px;
  z-index: 99;
  border-top: 1px solid #363744;
  z-index: 101;
  background: #202028;

  &:before {
    content: "";
    display: block;
    position: absolute;
    background: #202028;
    pointer-events: none;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  @media (max-width: 1024px) {
    display: flex;
  }
  &_item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    color: #6c6f8b;
    font-weight: 700;
    font-size: 12px;
    text-decoration: none;
    z-index: 3;
    flex-grow: 1;

    &:hover {
      color: #fff;

      svg {
        color: #ffb800;
      }
    }
  }
}

.menu_mob_div_1 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 138px;
  height: 54px;
  background: #202028;
  border-radius: 80px;
  top: -7px;
  z-index: 2;
}

.menu_mob_div_2 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 140px;
  height: 56px;
  background: #363744;
  border-radius: 80px;
  top: -8px;
  z-index: -1;
}

@media screen and (max-width: 1024px) {
  .menu_mob_item {
    -webkit-tap-highlight-color: transparent;

    &:active,
    &:focus {
      background-color: transparent;
      outline: none;
    }
  }
}
