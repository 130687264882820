.loader1_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.loader1 {
  width: 124px;
  height: 124px;
  border-radius: 50%;
  border: 12px solid rgba(57, 87, 170, 0.1);
  border-top: 12px solid #3957aa;
  animation: spin 2s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
