.drop_item {
  position: relative;
  background: linear-gradient(
      180deg,
      rgba(235, 75, 75, 0.3) 0%,
      rgba(245, 116, 116, 0.3) 100%
    ),
    radial-gradient(
      50% 100% at 43.55% 0%,
      rgba(235, 75, 75, 0.48) 0%,
      rgba(235, 75, 75, 0) 100%
    );
  border-radius: 8px;
  padding: 4px;
  height: 116px;
  display: flex;
  flex-direction: column;

  &_consumer {
    background: linear-gradient(
        180deg,
        rgba(176, 195, 217, 0.3) 0%,
        rgba(211, 221, 234, 0.3) 100%
      ),
      radial-gradient(
        50% 100% at 43.55% 0%,
        rgba(176, 195, 217, 0.48) 0%,
        rgba(176, 195, 217, 0) 100%
      );
  }

  &_military {
    background: linear-gradient(
        180deg,
        rgba(75, 105, 255, 0.3) 0%,
        rgba(117, 150, 255, 0.3) 100%
      ),
      radial-gradient(
        50% 100% at 43.55% 0%,
        rgba(75, 105, 255, 0.48) 0%,
        rgba(75, 105, 255, 0) 100%
      );
  }

  &_industrial {
    background: linear-gradient(
        180deg,
        rgba(94, 152, 217, 0.3) 0%,
        rgba(121, 178, 225, 0.3) 100%
      ),
      radial-gradient(
        50% 100% at 43.55% 0%,
        rgba(94, 152, 217, 0.48) 0%,
        rgba(94, 152, 217, 0) 100%
      );
  }

  &_restricted {
    background: linear-gradient(
        180deg,
        rgba(136, 71, 255, 0.3) 0%,
        rgba(170, 133, 255, 0.3) 100%
      ),
      radial-gradient(
        50% 100% at 43.55% 0%,
        rgba(136, 71, 255, 0.48) 0%,
        rgba(136, 71, 255, 0) 100%
      );
  }

  &_classified {
    background: linear-gradient(
        180deg,
        rgba(211, 44, 230, 0.3) 0%,
        rgba(226, 59, 250, 0.3) 100%
      ),
      radial-gradient(
        50% 100% at 43.55% 0%,
        rgba(211, 44, 230, 0.48) 0%,
        rgba(211, 44, 230, 0) 100%
      );
  }

  &_covert {
    background: linear-gradient(
        180deg,
        rgba(235, 75, 75, 0.3) 0%,
        rgba(245, 116, 116, 0.3) 100%
      ),
      radial-gradient(
        50% 100% at 43.55% 0%,
        rgba(235, 75, 75, 0.48) 0%,
        rgba(235, 75, 75, 0) 100%
      );
  }

  &_legendary {
    background: linear-gradient(
        180deg,
        rgba(255, 198, 0, 0.3) 0%,
        rgba(255, 229, 27, 0.3) 100%
      ),
      radial-gradient(
        50% 100% at 43.55% 0%,
        rgba(255, 198, 0, 0.48) 0%,
        rgba(255, 198, 0, 0) 100%
      );
  }
}

.drop_item_image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
}

.drop_item_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.drop_item_price,
.drop_item_percent {
  font-weight: 500;
  font-size: 14px;
  line-height: 114%;
  color: #ffffff;
  padding: 4px 4px 2px;
  background: rgba(19, 19, 24, 0.5);
  border-radius: 4px;
}

.drop_item_info {
  display: flex;
  flex-direction: column;
  margin-top: auto;
}

.drop_item_tag {
  font-weight: 500;
  font-size: 14px;
  line-height: 143%;
  color: #b3b5c6;
}

.drop_item_name {
  font-weight: 500;
  font-size: 14px;
  line-height: 143%;
  color: #ffffff;

  span {
    color: #ffb800;
  }
}
