.giveaways_list {
  overflow-x: auto;
}
.giveaways_item {
  width: 25%;
  border-radius: 8px;
  padding: 8px;
  position: relative;
  font-size: 14px;
  cursor: pointer;

  @media(max-width: 1024px) {
    min-width: 270px;
  }
  &.rarity-1 {
    background: linear-gradient(180deg, rgba(176, 195, 217, 0.3) 0%, rgba(211, 221, 234, 0.3) 100%), radial-gradient(270.88% 100% at 100% 50%, rgba(176, 195, 217, 0.48) 0%, rgba(176, 195, 217, 0) 100%);
  }
  &.rarity-2 {
    background: linear-gradient(180deg, rgba(75, 105, 255, 0.3) 0%, rgba(117, 150, 255, 0.3) 100%), radial-gradient(270.88% 100% at 100% 50%, rgba(75, 105, 255, 0.48) 0%, rgba(75, 105, 255, 0) 100%);
  }
  &.rarity-3 {
    background: linear-gradient(180deg, rgba(94, 152, 217, 0.3) 0%, rgba(121, 178, 225, 0.3) 100%), radial-gradient(270.88% 100% at 100% 50%, rgba(94, 152, 217, 0.48) 0%, rgba(94, 152, 217, 0) 100%);
  }
  &.rarity-4 {
    background: linear-gradient(180deg, rgba(136, 71, 255, 0.3) 0%, rgba(170, 133, 255, 0.3) 100%), radial-gradient(270.88% 100% at 100% 50%, rgba(136, 71, 255, 0.48) 0%, rgba(136, 71, 255, 0) 100%);
  }
  &.rarity-5 {
    background: linear-gradient(180deg, rgba(211, 44, 230, 0.3) 0%, rgba(226, 59, 250, 0.3) 100%), radial-gradient(270.88% 100% at 100% 50%, rgba(211, 44, 230, 0.48) 0%, rgba(211, 44, 230, 0) 100%);
  }
  &.rarity-6 {
    background: linear-gradient(180deg, rgba(235, 75, 75, 0.3) 0%, rgba(245, 116, 116, 0.3) 100%), radial-gradient(270.88% 100% at 100% 50%, rgba(235, 75, 75, 0.48) 0%, rgba(235, 75, 75, 0) 100%);
  }
  &.rarity-7 {
    background: linear-gradient(180deg, rgba(255, 198, 0, 0.3) 0%, rgba(255, 229, 27, 0.3) 100%), radial-gradient(270.88% 100% at 100% 50%, rgba(255, 198, 0, 0.48) 0%, rgba(255, 198, 0, 0) 100%);
  }
  &_image {
    position: absolute;
    top: 8px;
    right: 8px;
    height: 100px;
  }
  &_info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }
  &_info_about {
    line-height: 1.28571;
    margin-bottom: 18px;
    position: relative;
    font-weight: 700;
    
    &::before {
      content: "";
      position: absolute;
      top: calc(100% + 8px);
      left: 0;
      width: 124px;
      height: 2px;
      background: linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
      opacity: 0.2;
    }
  }
  &_info_category {
    font-weight: 700;
    margin-bottom: 8px;
  }
  &_info_wrap {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.14286;
  }
  &_info_timer {
    padding: 4px;
    background: rgba(54, 55, 68, .32);
    backdrop-filter: blur(8px);
    border-radius: 4px;
    display: flex;
    gap: 6px;
  }
  &_info_count {
    padding: 4px;
    background: rgba(54, 55, 68, .32);
    backdrop-filter: blur(8px);
    border-radius: 4px;
    display: flex;
    gap: 6px;
  }
  &_info_check {
    border-radius: 50%;
    background: #089408;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-left: auto;
  }
  &:hover {
    .giveaways_item_image {
      margin-top: -10px;
    }
  }
}

.giveaways_item1 {
  width: 100% !important;
}