.mobile_menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100dvh - 61px);
  background-color: #131318;
  z-index: 8;
  animation: slideUp 0.5s ease-in-out forwards;
  padding: 12px 16px;

  &.closing {
    animation: slideDown 0.5s ease-in-out forwards;
  }

  .profile_stats_left {
    margin-bottom: 16px;
  }
}

.mobile_menu_content {
  overflow-y: auto;
  max-height: calc(100dvh - 144px);
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

.mobile_menu_header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.sound_btn {
  width: 32px;
  height: 32px;
  padding: 0 !important;
  margin-left: auto;
}

.mobile_menu_login,
.mobile_menu_logout {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #ffb800;
  text-decoration: none;
  padding: 16px 8px;
  border-radius: 8px;
  background-color: #202028;
  margin-bottom: 16px;

  span {
    color: #ffffff;
  }
}

.mobile_menu_logout {
  color: #b3b5c6;

  span {
    color: #b3b5c6;
  }
}

.mobile_menu_language {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 16px 8px;
  border-radius: 8px;
  background-color: #202028;
  margin-bottom: 16px;

  img {
    border-radius: 4px;
    overflow: hidden;
  }

  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #ffffff;
  }
}

.arrow_right {
  margin-left: auto;
}

.mobile_menu_links {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px;
  border-radius: 8px;
  background: #202028;
  margin-bottom: 16px;

  a {
    display: flex;
    padding: 8px 0;
    align-items: center;
    gap: 8px;
    text-decoration: none;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
  }
}

.mobile_menu_links_divider {
  height: 2px;
  border-radius: 4px;
  background-color: #363744;
}

.mobile_menu_socials {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 0 4px;
  justify-content: space-between;
  margin-bottom: 16px;
}

.mobile_menu_social_item {
  color: #363744;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease-in-out;
  border-radius: 8px;
  overflow: hidden;
  width: 40px;
  height: 40px;

  &:nth-child(1) {
    &:hover {
      color: #d6f0fc;
    }
    &:active {
      color: #49c0f4;
    }
  }

  &:nth-child(2) {
    &:hover {
      color: #d6d3ff;
    }
    &:active {
      color: #9378ff;
    }
  }

  &:nth-child(3) {
    &:hover {
      color: #dec9ff;
    }
    &:active {
      color: #b684ff;
    }
  }

  &:nth-child(4) {
    &:hover {
      color: #d6f0fc;
    }
    &:active {
      color: #1d9bf0;
    }
  }

  &:nth-child(5) {
    &:hover {
      color: #d3d3f6;
    }
    &:active {
      color: #6767f6;
    }
  }

  &:nth-child(6) {
    &:hover {
      color: #ffd3d3;
    }
    &:active {
      color: #f63e3e;
    }
  }
}

.mobile_menu_profile {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 16px;
  padding: 8px;
  border-radius: 8px;
  background: #202028;

  .profile_info_mobile_id {
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    color: #6c6f8b;
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.profile_info_mobile_descr {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
}
