.header_bottom {
  padding: 13px 24px;
  display: flex;
  align-items: center;
  gap: 2px;
  overflow: hidden;
  background: #131318;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 8;
  min-height: 68px;

  @media (max-width: 1024px) {
    padding: 12px 16px;
  }
  &_right {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}

.header_lang {
  position: relative;
  width: 44px;
  height: 44px;
  border-radius: 4px;
  background: #202028;
  cursor: pointer;

  @media (max-width: 1024px) {
    height: 32px;
    width: 32px;
  }
  &_select {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 20px;
      height: auto;
      display: block;
      border-radius: 2px;
    }
  }
}

.header_profile {
  width: 44px;
  height: 44px;
  border-radius: 5px;
  overflow: hidden;
  display: block;

  @media (max-width: 1024px) {
    height: 32px;
    width: 32px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
}

.header_menu_dropdown {
  width: 44px;
  height: 44px;
  border-radius: 4px;
  background: #202028;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  transition: 0.2s ease-out;

  @media (max-width: 1024px) {
    height: 32px;
    width: 32px;
  }
  &:hover {
    background: #363744;
  }
  &:active {
    background: #131318;
  }
  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    display: block;
  }
}

.header_bottom_right {
  margin-left: auto;
}

.header_logo {
  @media (max-width: 1024px) {
    display: none;
  }
}
.header_logo_mob {
  display: none;

  @media (max-width: 1024px) {
    display: block;
  }
}
