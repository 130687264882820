@use "../../scss/styles/colors" as *;

.demo_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background-color: rgba(19, 19, 24, 0.48);
  backdrop-filter: blur(8px);
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease forwards;
}

.demo_modal_body {
  background: #131318;
  border-radius: 16px;
  width: 100%;
  max-width: 400px;
  padding: 24px;
  border: 2px solid #202028;
  animation: slideUp 0.3s ease forwards;

  &.closing {
    animation: slideDown 0.3s ease forwards;
  }

  @media (max-width: 767px) {
    border-radius: 16px 16px 0 0;
    margin-top: auto;
  }
}

.demo_modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    color: #ffffff;
  }
}

.demo_modal_close {
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  transition: background 0.2s;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
}

.demo_modal_content {
  display: flex;
  flex-direction: column;
}

.demo_modal_subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  color: #b3b5c6;
  margin-bottom: 20px;
}

.demo_modal_prize {
  position: relative;
  // background: linear-gradient(
  //     180deg,
  //     rgba(235, 75, 75, 0.3) 0%,
  //     rgba(245, 116, 116, 0.3) 100%
  //   ),
  //   radial-gradient(
  //     50% 100% at 43.55% 0%,
  //     rgba(235, 75, 75, 0.48) 0%,
  //     rgba(235, 75, 75, 0) 100%
  //   );
  border-radius: 8px;
  padding: 4px;
  height: 164px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &_item_consumer {
    background: $top_drop_item_consumer;
    color: $rarity_consumer;

    &:before {
      background: $top_drop_item_consumer;
    }
  }
  &_item_military {
    background: $top_drop_item_military;
    color: $rarity_military;
    &:before {
      background: $top_drop_item_military;
    }
  }
  &_item_industrial {
    background: $top_drop_item_industrial;
    color: $rarity_industrial;

    &:before {
      background: $top_drop_item_industrial;
    }
  }
  &_item_restricted {
    background: $top_drop_item_restricted;  
    color: $rarity_restricted;

    &:before {
      background: $top_drop_item_restricted;
    }
  }
  &_item_classified {
    background: $top_drop_item_classified; 
    color: $rarity_classified;

    &:before {
      background: $top_drop_item_classified;
    }
  }
  &_item_covert {
    background: $top_drop_item_covert;
    color: $rarity_covert;
    &:before {
      background: $top_drop_item_covert;
    }
  }
  &_item_legendary {
    background: $top_drop_item_legendary;
    color: $rarity_legendary;

    &:before {
      background: $top_drop_item_legendary;
    }
  }
}

.demo_modal_prize_image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  margin-bottom: 16px;
}

.demo_modal_prize_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.demo_modal_text {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #47485d;
  }

  a {
    color: #ffb800;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.demo_modal_text_red {
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #eb4b4b !important;
}

.demo_modal_prize_price,
.demo_modal_prize_percent {
  font-weight: 500;
  font-size: 14px;
  line-height: 114%;
  color: #ffffff;
  padding: 4px 4px 2px;
  background: rgba(19, 19, 24, 0.5);
  border-radius: 4px;
}

.demo_modal_prize_info {
  display: flex;
  flex-direction: column;
  margin-top: auto;
}

.demo_modal_prize_tag {
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  color: #b3b5c6;
  margin-bottom: 8px;
}

.demo_modal_prize_name {
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  color: #ffffff;

  span {
    color: #ffb800;
  }
}

.demo_modal_button {
  width: 100%;
  border-radius: 8px !important;
  margin-bottom: 20px;
  min-height: 48px;
}

.demo_modal_devider {
  width: 100%;
  height: 1px;
  background: #363744;
  margin-bottom: 20px;
}

.demo_modal_descr {
  border-radius: 8px;
  padding: 16px;
  background: #202028;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 114%;

  span {
    font-weight: 700;
    font-size: 14px;
    line-height: 114%;
    color: #ffb800;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(30px);
    opacity: 0;
  }
}
