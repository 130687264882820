.menu {
  display: flex;
  align-items: center;
  margin: 0 auto;
  gap: 24px;
  
  @media(max-width: 1024px) {
    display: none;
  }
  &_item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    color: #B3B5C6;
    font-weight: 700;
    font-size: 16px;
    text-decoration: none;
    
    &:hover {
      color: #fff;

      svg {
        color: #FFB800;
      }
    }
  }
}