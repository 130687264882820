// rarity top drop
$top_drop_item_consumer: linear-gradient(180deg, rgba(176, 195, 217, 0.3) 0%, rgba(211, 221, 234, 0.3) 100%), radial-gradient(50% 100% at 43.55% 0%, rgba(176, 195, 217, 0.48) 0%, rgba(176, 195, 217, 0) 100%);
$top_drop_item_military: linear-gradient(180deg, rgba(75, 105, 255, 0.3) 0%, rgba(117, 150, 255, 0.3) 100%), radial-gradient(50% 100% at 43.55% 0%, rgba(75, 105, 255, 0.48) 0%, rgba(75, 105, 255, 0) 100%);
$top_drop_item_industrial: linear-gradient(180deg, rgba(94, 152, 217, 0.3) 0%, rgba(121, 178, 225, 0.3) 100%), radial-gradient(50% 100% at 43.55% 0%, rgba(94, 152, 217, 0.48) 0%, rgba(94, 152, 217, 0) 100%);
$top_drop_item_restricted: linear-gradient(180deg, rgba(136, 71, 255, 0.3) 0%, rgba(170, 133, 255, 0.3) 100%), radial-gradient(50% 100% at 43.55% 0%, rgba(136, 71, 255, 0.48) 0%, rgba(136, 71, 255, 0) 100%);
$top_drop_item_classified: linear-gradient(180deg, rgba(211, 44, 230, 0.3) 0%, rgba(226, 59, 250, 0.3) 100%), radial-gradient(50% 100% at 43.55% 0%, rgba(211, 44, 230, 0.48) 0%, rgba(211, 44, 230, 0) 100%);
$top_drop_item_covert: linear-gradient(180deg, rgba(235, 75, 75, 0.3) 0%, rgba(245, 116, 116, 0.3) 100%), radial-gradient(50% 100% at 43.55% 0%, rgba(235, 75, 75, 0.48) 0%, rgba(235, 75, 75, 0) 100%);
$top_drop_item_legendary: linear-gradient(180deg, rgba(255, 198, 0, 0.3) 0%, rgba(255, 229, 27, 0.3) 100%), radial-gradient(50% 100% at 43.55% 0%, rgba(255, 198, 0, 0.48) 0%, rgba(255, 198, 0, 0) 100%);


// rarity colors
$rarity_consumer: #b0c3d9;
$rarity_military: #4b69ff;
$rarity_industrial: #5e98d9;
$rarity_restricted: #8847ff;
$rarity_classified: #d32ce6;
$rarity_covert: #eb4b4b;
$rarity_legendary: #ffc600;
