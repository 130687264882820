.header_top {
  padding: 2px 2px 0px 2px;
  display: flex;
  gap: 2px;
  overflow: hidden;
}

.header_online {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
  text-transform: uppercase;
  color: #fff;
  background: #202028;
  padding: 8px 20px;
  width: 82px;
  border-radius: 4px;

  &_img {
    margin-bottom: 4px;
  }

  @media(max-width: 1024px) {
    display: none;
  }
}

.header_online_count {
  font-weight: 700;
  font-size: 16px;
}

.header_online_text {
  opacity: 0.64;
  font-weight: 700;
  font-size: 12px;
  line-height: 1.16667;
}

.header_top_change {
  display: flex;
  flex-direction: column;
  gap: 2px;

  @media(max-width: 1024px) {
    display: none;
  }
  &_item {
    width: 40px;
    height: 40px;
    background: #202028;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background:#363744
    }
  }

  .drop-best {
    &-wrap {
      background-color: #050b16;
      height: 38px;
      width: 38px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  a {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 40px;
    background-color: rgba(5, 11, 22, 1);
    transition: all 0.5s;
    position: relative;

    &:hover {
      .badge {
        opacity: 1;
      }
    }
  }

  // .badge {
  //   position: absolute;
  //   top: 50%;
  //   left: 33px;
  //   transform: translateY(-50%);
  //   height: 20px;
  //   padding: 0 8px;
  //   color: #fff;
  //   background-color: rgb(81, 86, 102);
  //   border-radius: 20px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   font-size: 12px;
  //   white-space: nowrap;
  //   opacity: 0;
  //   transition: all 0.5s;
  // }
}